import { Injectable } from '@angular/core';
import { ControlTowerService } from '../control-tower.service';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../middleware/token/token.service';
import { IResponseChangePassword } from './change-password/change-password.interface';

const ROUTES = {
  USER_COMMISSION: '/auth/change-password',
};
@Injectable({
  providedIn: 'root',
})
export class AuthService extends ControlTowerService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }
  async changePassword(newPassword: any): Promise<IResponseChangePassword> {
    const response = await this.request('put', ROUTES.USER_COMMISSION, {
      newPassword,
    }).toPromise();
    return {
      message: response.body.message,
      status: response.status,
    };
  }
}
