import { HttpErrorResponse } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { IHeader, TColumn } from './xlsx.interface';

export class UtilXlsx {
  headersTemplate: string[];
  headersTable: string[];
  headersType: TColumn[];
  data: any[];

  constructor() {}

  getWorkBook = (file: File): Promise<XLSX.WorkBook> => {
    return new Promise((resolve, reject) => {
      try {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e): any => {
          const data = new Uint8Array(fileReader.result as ArrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          const bstr = arr.join('');
          const workbook = XLSX.read(bstr, { type: 'binary' });
          resolve(workbook);
        };
      } catch (e) {
        reject(null);
      }
    });
  };

  readFile = async (file: File): Promise<any[]> => {
    const workbook = await this.getWorkBook(file);
    const first_sheet_name = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[first_sheet_name];
    const values = XLSX.utils.sheet_to_json(worksheet);
    return values;
  };

  setHeadersAndData = async (file: File): Promise<void> => {
    const values = await this.readFile(file);
    this.headersTemplate = Object.keys(values[0]);
    this.headersTable = Object.values(values[0]) as string[];
    this.headersType = Object.values(values[1]) as TColumn[];
    this.data = values;
  };

  isCorrectTemplate = () => {
    if (this.data.length > 2) {
      this.setThorwError(
        'La Plantilla no debe contener datos adicionales al encabezado.'
      );
    }
  };

  validateHeaders = () => {
    for (let i = 0; i < this.headersTemplate.length; i++) {
      if (this.headersTemplate[i] === '') {
        this.setThorwError(`Falta el nombre de la fila 1 y columna ${i + 1}`);
      }
    }
    for (let i = 0; i < this.headersTable.length; i++) {
      if (this.headersTable[i] === '') {
        this.setThorwError(`Falta el nombre de la fila 2 y columna ${i + 1}`);
      }
    }
    const types: TColumn[] = ['Text', 'Number', 'Date', 'Time'];
    for (let i = 0; i < this.headersType.length; i++) {
      if (!types.includes(this.headersType[i])) {
        this.setThorwError(`Falta el tipo de la fila 3 y columna ${i + 1}`);
      }
    }
    if (
      this.headersTemplate.length !== this.headersTable.length ||
      this.headersTemplate.length !== this.headersType.length
    ) {
      this.setThorwError(
        'El número de columnas de la fila 1, 2 y 3 no coinciden'
      );
    }
  };

  getHeaders = (): IHeader[] => {
    this.validateHeaders();
    const headers: IHeader[] = [];
    for (let i = 0; i < this.headersTemplate.length; i++) {
      headers.push({
        template: this.headersTemplate[i],
        table: this.headersTable[i],
        type: this.headersType[i],
      });
    }
    return headers;
  };

  validateData = () => {
    for (let nroRow = 2; nroRow < this.data.length; nroRow++) {
      const rowValues = Object.values(this.data[nroRow]) as any[];
      const posRow = nroRow + 2;
      if (rowValues.length !== this.headersTemplate.length) {
        this.setThorwError(
          `El número de columnas de la fila ${posRow} no coinciden`
        );
      }
      for (let nroCol = 0; nroCol < rowValues.length; nroCol++) {
        const posCol = nroCol + 1;
        const value = rowValues[nroCol];
        if (this.headersType[nroCol] === 'Text') {
          if (value === '') {
            this.setThorwError(
              `Falta el valor de la fila ${posRow} y columna ${posCol}`
            );
          }
        }
        if (this.headersType[nroCol] === 'Date') {
          if (typeof value === 'number') {
            if (isNaN(Number(value))) {
              this.setThorwError(
                `El valor de la fila ${posRow} y columna ${posCol} no tiene el formato de fecha`
              );
            }
            if (isNaN(Date.parse(this.excelDateToJSDate(value)))) {
              this.setThorwError(
                `El valor de la fila ${posRow} y columna ${posCol} no es una fecha`
              );
            }
          }
          if (typeof value === 'string') {
            if (value.match(/^\d{2}\/\d{2}\/\d{4}$/) === null) {
              this.setThorwError(
                `El valor de la fila ${posRow} y columna ${posCol} no tiene el formato de fecha`
              );
            }
            if (isNaN(Date.parse(this.stringDateToEnglishDate(value)))) {
              this.setThorwError(
                `El valor de la fila ${posRow} y columna ${posCol} no es una fecha válida`
              );
            }
          }
        }
        if (this.headersType[nroCol] === 'Time') {
          if (typeof value === 'number') {
            if (isNaN(Number(value))) {
              this.setThorwError(
                `El valor de la fila ${posRow} y columna ${posCol} no tiene el formato de tiempo`
              );
            }
          }
          if (typeof value === 'string') {
            if (
              value.match(
                /^([0-9]|[0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/
              ) === null
            ) {
              this.setThorwError(
                `El valor de la fila ${posRow} y columna ${posCol} no tiene el formato de tiempo`
              );
            }
          }
        }
        if (this.headersType[nroCol] === 'Number') {
          if (isNaN(Number(value))) {
            this.setThorwError(
              `El valor de la fila ${posRow} y columna ${posCol} no es un número`
            );
          }
        }
      }
    }
  };

  setThorwError = (message: string) => {
    throw new HttpErrorResponse({
      error: {
        message,
      },
      status: 404,
    });
  };

  excelDateToJSDate = (excelDate: number) => {
    const utcDays = Math.floor(excelDate - 25569);
    const dateInfo = new Date(utcDays * 86400 * 1000);
    const [year, month, day] = [
      dateInfo.getFullYear(),
      dateInfo.getMonth() + 1,
      dateInfo.getDate(),
    ];
    const date = `${month}/${day}/${year}`;
    return date;
  };

  stringDateToEnglishDate = (date: string) => {
    const [day, month, year] = date.split('/');
    const dateEnglish = `${month}/${day}/${year}`;
    return dateEnglish;
  };
}
