import { Component } from '@angular/core';

@Component({
  selector: 'ngx-boardsBI',
  templateUrl: './boardsBI.component.html',
  styleUrls: ['./boardsBI.component.scss'],
})
export class BoardsBIComponent {
  views = {
    1: ['1,1'],
    2: {
      transferencia: ['2,1', '2,2', '2,3'],
      recepcion: ['2,4', '2,5'],
      stock: ['2,6', '2,7'],
    },
    3: ['3,1', '3,2', '3,3'],
    4: ['4,1', '4,2', '4,3', '4,4', '4,5', '4,6', '4,7'],
    5: ['5,1', '5,2'],
    6: {
      propio: ['6,1', '6,2'],
      tercero: ['6,3', '6,4', '6,5'],
      tmPpi: ['6,6', '6,7', '6,8'],
      tm: ['6,9', '6,10', '6,11'],
      transito: ['6,12'],
    },
  };
  url: string = null;
  constructor() {}
  onSetUrl(event: string): void {
    this.url = event;
  }
  onReturnHome(): void {
    this.url = null;
  }
}
