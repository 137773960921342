import { Component } from '@angular/core';
import {
  ITokenPayload,
  TActionDataEntry,
} from '../../../middleware/token/token.interface';
import { TokenService } from '../../../middleware/token/token.service';

type TPermissionAction = {
  [key in TActionDataEntry]: boolean;
};

@Component({
  selector: 'ngx-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.scss'],
})
export class DataEntryComponent {
  user: ITokenPayload;
  permissionAction: TPermissionAction = {
    upload: false,
    downloadTemplate: false,
    createTemplate: false,
    delete: false,
  };
  showComponentAdmin: boolean = false;
  constructor(private tokenService: TokenService) {
    this.user = this.tokenService.getPayload();
    if (this.user) {
      const actions = this.user.permissions.find(
        (x) => x.module === 'adminDataEntry'
      ).actionDataEntry;
      for (const action of actions) {
        this.permissionAction[action] = true;
      }
    }
  }
}
