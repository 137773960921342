import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ChangePasswordValidator } from './change-password.validator';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  settingAlert = {};
  loading: boolean = false;
  fg = this.fb.group({
    password: [],
    repeatPassword: [],
  });

  constructor(public authService: AuthService, private fb: FormBuilder) {}

  ngOnInit() {
    this.fg = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(5)]],
      repeatPassword: ['', [Validators.required]],
    });
    this.fg.setValidators(ChangePasswordValidator.passwordEqual());
  }

  getStatusFg(itemFg: string): string {
    if (!(this.fg.get(itemFg).dirty || this.fg.get(itemFg).touched)) {
      return 'info';
    }
    if (this.fg.get(itemFg).valid) {
      return 'success';
    }
    return 'danger';
  }

  async onSubmit() {
    const { password } = this.fg.value;
    try {
      this.loading = true;
      this.settingAlert = await this.authService.changePassword(password);
      this.loading = false;
      this.fg.reset();
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }
}
