import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbWindowRef,
  NbWindowService,
} from '@nebular/theme';
import { ChangePasswordComponent } from '../../../@control-tower/auth/change-password/change-password.component';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TokenService } from '../../../middleware/token/token.service';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  userToken: any;
  user = {
    username: '',
  };
  windowsRef: NbWindowRef = null;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [
    { title: 'Cambiar Contraseña' },
    { title: 'Salir', link: '/auth/login' },
  ];
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private windowService: NbWindowService,
    private tokenService: TokenService
  ) {
    this.userToken = this.tokenService.getPayload();
    this.user.username = this.userToken.email.split('@')[0];
  }

  ngOnInit() {
    this.menuService.onItemClick().subscribe((event) => {
      this.onItemSelection(event.item.title);
    });

    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  onItemSelection(title) {
    if (title === 'Salir') {
      localStorage.clear();
    } else if (title === 'Cambiar Contraseña') {
      this.windowsRef = this.windowService.open(ChangePasswordComponent, {
        title,
        context: {},
        closeOnEsc: true,
      });
    }
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
