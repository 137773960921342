import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { IUser, IUserGet, IUserUpdate } from '../user.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ISettingAlert } from '../../../utils/alert/alert.interface';
import {
  TModulesApp,
  IPermission,
  TITLE_MODULE,
  TActionDataEntry,
} from '../../../../middleware/token/token.interface';
import { ViewService } from '../../../mangement/data-entry/view/view.service';
import { IView } from '../../../mangement/data-entry/view/view.interface';
@Component({
  selector: 'ngx-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit, OnChanges {
  @Input() userSelectedToUpdate: IUser;
  @Output() closeUpdateUser = new EventEmitter<any>();
  @Output() userUpdated = new EventEmitter<boolean>();
  userToUpdate: IUserGet = null;
  fg = this.fb.group({
    email: [],
    resetPassword: [false],
  });
  loading = false;
  users: Array<IUser> = [];
  settingAlert: ISettingAlert = {};
  titleModules = TITLE_MODULE;
  permissions: IPermission[] = [];
  beAbleViewsBoarsBI = false;
  beAbleAction = false;
  views: Array<IView> = [];

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private viewService: ViewService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const currentUser = changes.userSelectedToUpdate.currentValue;
    if (currentUser) {
      this.userToUpdate = currentUser;
      this.setUserInForm();
    }
  }

  async ngOnInit() {
    await this.getUsers();
    await this.getViews();
    this.fg = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(5)]],
      resetPassword: [false],
    });
    this.setUserInForm();
    this.setInitPermission();
  }

  setUserInForm() {
    this.fg.setValue({
      email: this.userToUpdate.email.split('@')[0],
      resetPassword: false,
    });
  }

  getPermissionsModule(module: TModulesApp) {
    return this.userToUpdate.permissions.find((item) => item.module === module);
  }
  setPermissionModule(module: TModulesApp) {
    return this.getPermissionsModule(module).permitted;
  }
  setPermissionView(viewId: number) {
    return this.getPermissionsModule('boardsBI').viewsIds.includes(viewId);
  }
  setPermissionAction(action: TActionDataEntry) {
    return this.getPermissionsModule('adminDataEntry').actionDataEntry.includes(
      action
    );
  }

  async onSubmitFg() {
    const user = this.fg.value as IUserUpdate;
    try {
      this.loading = true;
      let response = null;
      response = await this.userService.updateUserAsync({
        id: this.userToUpdate.id,
        email: user.email + '@alicorp.com.bo',
        permissions: this.permissions,
        resetPassword: user.resetPassword,
      });
      this.userUpdated.emit(true);
      this.loading = false;
      this.settingAlert = response;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  getStatusFg(itemFg: string): string {
    if (!(this.fg.get(itemFg).dirty || this.fg.get(itemFg).touched)) {
      return 'info';
    }
    if (this.fg.get(itemFg).valid) {
      return 'success';
    }
    return 'danger';
  }

  async getUsers() {
    try {
      this.loading = true;
      this.users = await this.userService.listUsersAsync();
      this.loading = false;
      this.fg.reset();
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  toggle(event: boolean, module: TModulesApp) {
    this.permissions.find((item) => item.module === module).permitted = event;
    this.beAbleViewsBoarsBI =
      module === 'boardsBI' ? event : this.beAbleViewsBoarsBI;
    this.beAbleAction = module === 'adminDataEntry' ? event : this.beAbleAction;
  }

  toggleBoardsBI(event: boolean, viewId: number) {
    let viewsIds = this.permissions.find(
      (item) => item.module === 'boardsBI'
    ).viewsIds;
    if (event) {
      viewsIds.push(viewId);
    } else {
      viewsIds = viewsIds.filter((item) => item !== viewId);
    }
    this.permissions.find((item) => item.module === 'boardsBI').viewsIds =
      viewsIds;
  }

  toggleActionDataEntry(event: boolean, typeAction: TActionDataEntry) {
    let actionDataEntry = this.permissions.find(
      (item) => item.module === 'adminDataEntry'
    ).actionDataEntry;
    if (event) {
      actionDataEntry.push(typeAction);
    } else {
      actionDataEntry = actionDataEntry.filter((item) => item !== typeAction);
    }
    this.permissions.find(
      (item) => item.module === 'adminDataEntry'
    ).actionDataEntry = actionDataEntry;
  }

  setInitPermission() {
    const permission: IPermission[] = [];
    for (const titleModule of this.titleModules) {
      if (titleModule.module === 'boardsBI') {
        permission.push({
          module: titleModule.module,
          permitted: this.setPermissionModule(titleModule.module),
          viewsIds: this.getPermissionsModule('boardsBI').viewsIds,
        });
      } else if (titleModule.module === 'adminDataEntry') {
        permission.push({
          module: titleModule.module,
          permitted: this.setPermissionModule(titleModule.module),
          actionDataEntry:
            this.getPermissionsModule('adminDataEntry').actionDataEntry,
        });
      } else {
        permission.push({
          module: titleModule.module,
          permitted: this.setPermissionModule(titleModule.module),
        });
      }
    }
    this.permissions = permission;
  }

  async getViews() {
    try {
      this.loading = true;
      this.views = await this.viewService.listViewsAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  onCancelUpdateUser() {
    this.closeUpdateUser.emit();
  }
}
