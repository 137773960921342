<nb-card status="primary" [nbSpinner]="loading" nbSpinnerStatus="info">
  <nb-card-body>
    <ngx-alert [setting]="settingAlert"></ngx-alert>
    <form [formGroup]="fg" (ngSubmit)="onSubmitFg()">
      <div class="form-group row">
        <label for="inputView" class="label col-sm-3 col-form-label"
          >Nombre de Vista</label
        >
        <div class="col-sm-9">
          <input
            nbInput
            [status]="getStatusFg('nameView')"
            fullWidth
            filled
            formControlName="nameView"
          />
          <div
            *ngIf="
              fg.get('nameView').invalid &&
              (fg.get('nameView').dirty || fg.get('nameView').touched)
            "
            class="text-danger"
          >
            <div *ngIf="fg.get('nameView').errors?.required">
              No puede quedar vacio.
            </div>
            <div *ngIf="fg.get('nameView').errors?.minlength">
              Debe tener como minimo 5 caracteres.
            </div>
            <div *ngIf="fg.get('nameView').errors?.existing">
              Ya existe el campo.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="offset-sm-3 col-sm-9">
          <button
            type="submit"
            nbButton
            outline
            status="success"
            [disabled]="fg.invalid"
          >
            <nb-icon icon="plus-circle"></nb-icon>
            CREAR
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
