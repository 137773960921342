import { AbstractControl, ValidatorFn } from '@angular/forms';
import { IView } from '../view.interface';

export class NewViewValidator {
  constructor() {}

  static nameViewExisting(views: Array<IView>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const nameView = control.value;
      const existingNameView = views.find((view) => nameView === view.nameView);
      if (existingNameView) {
        return {
          existing: true,
        };
      }
      return null;
    };
  }
}
