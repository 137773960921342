<div>
  <h5 class="text-primary">
    <nb-icon icon="person"></nb-icon> ADMISTRACIÓN DE USUARIOS
  </h5>
</div>
<br />
<div class="row">
  <div class="col-md-6" *ngIf="!userSelected">
    <ngx-create-user (userCreated)="onUserCreated()"></ngx-create-user>
  </div>
  <div class="col-md-6" *ngIf="userSelected">
    <ngx-update-user
      [userSelectedToUpdate]="userSelected"
      (closeUpdateUser)="onCloseUserUpdate()"
      (userUpdated)="onUserUpdated()"
    ></ngx-update-user>
  </div>
  <div class="col-md-6">
    <ngx-list-user
      (setUser)="onSetUserToUpdate($event)"
      [reloadUsers]="reloadUsers"
    ></ngx-list-user>
  </div>
</div>
