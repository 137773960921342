import { FormGroup, ValidatorFn } from '@angular/forms';
import { IUser } from '../user.interface';

export class CreateUserValidator {
  constructor() {}

  static validateEmail(users: Array<IUser>): ValidatorFn {
    const emailCorp = '@alicorp.com.bo';
    return (fg: FormGroup): null => {
      const ctrlEmail = fg.controls['email'];
      const email = ctrlEmail.value + emailCorp;
      const user = users.find((user) => user.email === email);
      if (user) {
        ctrlEmail.setErrors({
          alreadyExists: true,
        });
      }
      return null;
    };
  }
}
