<nb-card [nbSpinner]="loading" nbSpinnerStatus="info">
  <nb-card-header class="text-primary">
    <nb-icon icon="file-medical" pack="fas"></nb-icon>

    <strong> CREAR PLANTILLA </strong>

    <button
      style="float: right"
      nbButton
      outline
      status="warning"
      (click)="onNewView()"
    >
      <nb-icon icon="folder-add"></nb-icon>
      CREAR VISTA
    </button>
  </nb-card-header>
  <nb-card-body>
    <ngx-alert [setting]="settingAlert"></ngx-alert>
    <form [formGroup]="fg" (ngSubmit)="onSubmitFg()">
      <div class="form-group row">
        <label for="inputView" class="label col-sm-3 col-form-label"
          >Vista</label
        >
        <div class="col-sm-9">
          <nb-select
            fullWidth
            filled
            placeholder="Seleccionar Vista"
            formControlName="viewId"
          >
            <nb-option *ngFor="let view of views" [value]="view.id">{{
              view.nameView
            }}</nb-option>
          </nb-select>
        </div>
      </div>

      <div class="form-group row">
        <label for="inputTemplate" class="label col-sm-3 col-form-label"
          >Nombre de Plantilla</label
        >
        <div class="col-sm-9">
          <input
            nbInput
            fullWidth
            filled
            [status]="getStatusFg('nameTemplate')"
            placeholder="Nombre de plantilla"
            formControlName="nameTemplate"
          />
          <div
            *ngIf="
              fg.get('nameTemplate').invalid &&
              (fg.get('nameTemplate').dirty || fg.get('nameTemplate').touched)
            "
            class="text-danger"
          >
            <div *ngIf="fg.get('nameTemplate').errors?.required">
              No puede quedar vacio.
            </div>
            <div *ngIf="fg.get('nameTemplate').errors?.minlength">
              Debe tener como minimo 5 caracteres.
            </div>
            <div *ngIf="fg.get('nameTemplate').errors?.alreadyExists">
              Ya existe el campo.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="inputTable" class="label col-sm-3 col-form-label"
          >Tabla</label
        >
        <div class="col-sm-9">
          <nb-select
            fullWidth
            filled
            placeholder="Seleccionar Tabla"
            formControlName="nameTable"
          >
            <nb-option
              *ngFor="let table of tables"
              [value]="table.table_name"
              >{{ table.table_name }}</nb-option
            >
          </nb-select>
        </div>
      </div>

      <div class="form-group row">
        <label for="inputFile" class="label col-sm-3 col-form-label">
          Plantilla Excel
        </label>
        <div class="col-sm-9">
          <input
            type="file"
            nbInput
            fullWidth
            placeholder="Cargar Excel"
            accept=".xlsx"
            (change)="onChangeNewTemplateExcel($event)"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="offset-sm-3 col-sm-9">
          <button
            type="submit"
            nbButton
            outline
            status="success"
            [disabled]="fg.invalid || !validFile"
          >
            <nb-icon icon="plus-circle"></nb-icon>
            CREAR
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
