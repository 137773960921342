import { Injectable } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { ITokenPayload } from './token.interface';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token: any;

  constructor(private authService: NbAuthService) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      this.token = token;
    });
  }
  getToken() {
    return this.token;
  }
  getPayload(): ITokenPayload | undefined {
    if (this.token.isValid()) {
      return this.token.getPayload();
    }
    return undefined;
  }
}
