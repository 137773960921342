import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ChangePasswordValidator {
  constructor() {}

  static passwordEqual(): ValidatorFn {
    return (fg: FormGroup): ValidationErrors | null => {
      const ctrlPassword = fg.controls['password'];
      const ctrlRepeatPassword = fg.controls['repeatPassword'];
      if (ctrlPassword.value !== ctrlRepeatPassword.value) {
        ctrlRepeatPassword.setErrors({
          notEqual: true,
        });
      }
      return null;
    };
  }
}
