import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { TokenService } from '../middleware/token/token.service';

export interface IResponse {
  message: string;
  payload: any;
}

@Injectable({
  providedIn: 'root',
})
export class ControlTowerService {
  constructor(public http: HttpClient, public tokenService: TokenService) {}
  request(
    method: string,
    url: string,
    data?: any,
    responseType?: any
  ): Observable<HttpResponse<Object | ArrayBuffer | any>> {
    const token = this.tokenService.getToken();
    const urlEnv = environment.baseUrl + url;
    return this.http.request(method, urlEnv, {
      body: data,
      responseType: responseType || 'json',
      observe: 'response',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
