import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IView } from '../view/view.interface';
import { ITemplate } from '../template/template-data-entry.interface';
import { ISettingAlert } from '../../../utils/alert/alert.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { TemplateService } from '../template/template-data-entry.service';
import { ViewService } from '../view/view.service';
import { DataEntryService } from '../data-entry.service';
import { DeleteDataEntryValidator } from './delete-data-entry.validator';
import { IDeleteDataEntry } from './delete-data-entry.interface';

@Component({
  selector: 'ngx-delete-data-entry',
  templateUrl: './delete-data-entry.component.html',
  styleUrls: ['./delete-data-entry.component.scss'],
})
export class DeleteDataEntryComponent implements OnInit {
  fg = this.fb.group({
    viewId: ['', [Validators.required]],
    nameTemplate: ['', [Validators.required]],
    code: [''],
  });

  views: Array<IView> = [];
  templates: Array<ITemplate> = [];
  templateView: Array<ITemplate> = [];
  loading: boolean = false;
  settingAlert: ISettingAlert = {};

  constructor(
    private fb: FormBuilder,
    private templateService: TemplateService,
    private viewService: ViewService,
    private dataEntryService: DataEntryService
  ) {}

  async ngOnInit() {
    await this.getViews();
    await this.getTemplates();
    this.fg.setValidators(DeleteDataEntryValidator.validateCode());
    this.fg.controls['viewId'].valueChanges.subscribe((viewId: number) => {
      this.fg.controls['nameTemplate'].setValue('');
      if (this.views && this.templates && viewId) {
        this.templateView = this.templates.filter(
          (template) => viewId == template.viewId
        );
      }
    });
  }

  async onSubmitFg() {
    const deleteDataEntry = this.fg.value as IDeleteDataEntry;
    try {
      this.loading = true;
      const viewId = this.views.find(
        (view) => view.id === deleteDataEntry.viewId
      ).id;
      const resultDelete = await this.dataEntryService.deleteExcelAsync({
        viewId: viewId,
        nameTemplate: deleteDataEntry.nameTemplate,
        code: String(deleteDataEntry.code),
      });
      this.loading = false;
      this.settingAlert = resultDelete;
      this.fg.reset();
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  getStatusFg(itemFg: string): string {
    if (!(this.fg.get(itemFg).dirty || this.fg.get(itemFg).touched)) {
      return 'info';
    }
    if (this.fg.get(itemFg).valid) {
      return 'success';
    }
    return 'danger';
  }

  async getViews() {
    try {
      this.loading = true;
      this.views = await this.viewService.listViewsAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  async getTemplates() {
    try {
      this.loading = true;
      this.templates = await this.templateService.listTemplatesAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }
}
