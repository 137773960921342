import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NbGlobalLogicalPosition, NbToastrService } from '@nebular/theme';
import { IToastr, IToastrInputRequest } from './toastr.interface';
@Component({
  selector: 'ngx-toastr',
  template: '',
  styles: [''],
})
export class ToastrComponent implements OnInit {
  @Input() toastrInput: IToastrInputRequest;
  toastr: IToastr;
  @HostBinding('class')
  className = 'example-items-rows';

  constructor(private toastrService: NbToastrService) {}

  ngOnInit(): void {
    this.toastr = {
      message: this.toastrInput.message,
      title: this.toastrInput.title,
      config: {
        destroyByClick: true,
        position: NbGlobalLogicalPosition.TOP_END,
        duration: 3000,
        status: this.toastrInput.status ? this.toastrInput.status : 'primary',
      },
    };
    this.showToast();
  }

  showToast() {
    this.toastrService.show(
      this.toastr.title,
      this.toastr.message,
      this.toastr.config
    );
  }
}
