import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ITokenPayload } from '../../../middleware/token/token.interface';
import { TokenService } from '../../../middleware/token/token.service';
import { IBoardPage, BOARDS } from '../boardsBI.interface';

@Component({
  selector: 'ngx-button-page',
  templateUrl: './button-page.component.html',
  styleUrls: ['./button-page.component.scss'],
})
export class ButtonPageComponent implements OnChanges {
  @Input() codeViewPage: string;
  @Output() sendUrlPageSelected = new EventEmitter<string>();
  page: IBoardPage;
  permitted: boolean = false;
  user: ITokenPayload;
  viewsIdsPermitted: number[] = [];

  constructor(private tokenService: TokenService) {
    this.user = this.tokenService.getPayload();
    if (this.user) {
      const permissions = this.user.permissions;
      this.viewsIdsPermitted = permissions.find(
        (permissions) =>
          permissions.module === 'boardsBI' && permissions.permitted
      ).viewsIds;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.codeViewPage.currentValue) {
      const [codeView, codePage] = this.codeViewPage.split(',');
      this.page = BOARDS[Number(codeView)][Number(codePage)];
      this.permitted = this.viewsIdsPermitted.includes(Number(codeView));
    }
  }

  onEmitCodePageSelecte() {
    this.sendUrlPageSelected.emit(this.page.url);
  }
}
