export type TModulesApp = 'boardsBI' | 'users' | 'adminDataEntry';
export type TActionDataEntry =
  | 'upload'
  | 'downloadTemplate'
  | 'createTemplate'
  | 'delete';
export interface IMenuTitleAndModule {
  title: string;
  module: TModulesApp;
  showsViews?: boolean;
  showActionDataEntry?: boolean;
  actionsDataEntry?: { name: string; type: TActionDataEntry }[];
}
export const TITLE_MODULE: IMenuTitleAndModule[] = [
  {
    title: 'Tableros BI',
    module: 'boardsBI',
    showsViews: true,
  },
  {
    title: 'Administración',
    module: 'adminDataEntry',
    showActionDataEntry: true,
    actionsDataEntry: [
      { name: 'Subir Data Entry', type: 'upload' },
      { name: 'Descargar Template', type: 'downloadTemplate' },
      { name: 'Crear Template', type: 'createTemplate' },
      { name: 'Eliminar Data Entry', type: 'delete' },
    ],
  },
  {
    title: 'Usuarios',
    module: 'users',
  },
];
export interface IPermission {
  module: TModulesApp;
  permitted: boolean;
  actionDataEntry?: TActionDataEntry[];
  viewsIds?: number[];
}
export interface ITokenPayload {
  id: number;
  email: string;
  permissions: IPermission[];
}
