import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ControlTowerService } from '../../control-tower.service';
import { TokenService } from '../../../middleware/token/token.service';
import {
  IDeleteDataEntry,
  IResultDeleteDataEntry,
} from './delete/delete-data-entry.interface';

const ROUTES = {
  EXCEL: '/data-entry/excel',
};

@Injectable({
  providedIn: 'root',
})
export class DataEntryService extends ControlTowerService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  async uploadExcelAsync(file: File): Promise<HttpResponse<Object>> {
    const formData = new FormData();
    formData.append('dataEntryExcel', file);
    return await this.request('post', ROUTES.EXCEL, formData).toPromise();
  }

  async deleteExcelAsync(
    infoDelete: IDeleteDataEntry
  ): Promise<IResultDeleteDataEntry> {
    const response = await this.request(
      'delete',
      ROUTES.EXCEL,
      infoDelete
    ).toPromise();
    return {
      message: response.body.message,
      status: response.status,
    };
  }
}
