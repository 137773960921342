<nb-card [nbSpinner]="loading" nbSpinnerStatus="info">
  <nb-card-header class="text-primary">
    <nb-icon icon="person-add"></nb-icon>
    <strong> CREAR USUARIO </strong>
  </nb-card-header>
  <nb-card-body>
    <ngx-alert [setting]="settingAlert"></ngx-alert>
    <form [formGroup]="fg" (ngSubmit)="onSubmitFg()">
      <div class="form-group row">
        <label for="inputView" class="label col-sm-2 col-form-label"
          >Email</label
        >
        <div class="col-sm-4">
          <input
            nbInput
            [status]="getStatusFg('email')"
            fullWidth
            filled
            formControlName="email"
          />
          <div
            *ngIf="
              fg.get('email').invalid &&
              (fg.get('email').dirty || fg.get('email').touched)
            "
            class="text-danger"
          >
            <div *ngIf="fg.get('email').errors?.required">
              No puede quedar vacio.
            </div>
            <div *ngIf="fg.get('email').errors?.minlength">
              Debe tener al menos 5 caracteres.
            </div>
            <div *ngIf="fg.get('email').errors?.alreadyExists">
              Ya esta registrado el email.
            </div>
          </div>
        </div>
        <label for="inputView" class="label col-sm-3 col-form-label"
          >@alicorp.com.bo</label
        >
      </div>

      <div class="form-group row">
        <label for="inputPermissions" class="label col-sm-2 col-form-label"
          >Permisos</label
        >
        <div class="col-sm-9">
          <div *ngFor="let titleModule of titleModules">
            <nb-checkbox (checkedChange)="toggle($event, titleModule.module)">{{
              titleModule.title
            }}</nb-checkbox>
            <div class="col-sm-12">
              <div *ngIf="titleModule.showsViews">
                <div *ngFor="let view of views">
                  <nb-checkbox
                    (checkedChange)="toggleBoardsBI($event, view.id)"
                    [disabled]="!beAbleViewsBoarsBI"
                    >{{ view.nameView }}</nb-checkbox
                  >
                </div>
              </div>
              <div *ngIf="titleModule.showActionDataEntry">
                <div *ngFor="let action of titleModule.actionsDataEntry">
                  <nb-checkbox
                    (checkedChange)="toggleActionDataEntry($event, action.type)"
                    [disabled]="!beAbleAction"
                    >{{ action.name }}</nb-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="offset-sm-3 col-sm-9">
          <button
            type="submit"
            nbButton
            outline
            status="success"
            [disabled]="fg.invalid"
          >
            <nb-icon icon="plus-circle"></nb-icon>
            CREAR
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
