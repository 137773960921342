<div *ngIf="permissionAction.upload">
  <div>
    <h5 class="text-primary">
      <nb-icon icon="cloud-upload-outline"></nb-icon> SUBIR DATA ENTRY
    </h5>
  </div>
  <br />
  <div class="row">
    <div class="col-md-12">
      <ngx-upload-data-entry></ngx-upload-data-entry>
    </div>
  </div>
</div>

<div
  *ngIf="permissionAction.downloadTemplate || permissionAction.createTemplate"
>
  <div>
    <h5 class="text-primary">
      <nb-icon icon="file-text-outline"></nb-icon> PLANTILLA DATA ENTRY
    </h5>
  </div>
  <br />
  <div class="row">
    <div class="col-md-6" *ngIf="permissionAction.downloadTemplate">
      <ngx-download-template-data-entry></ngx-download-template-data-entry>
    </div>

    <div class="col-md-6" *ngIf="permissionAction.createTemplate">
      <ngx-create-template-data-entry></ngx-create-template-data-entry>
    </div>
  </div>
</div>

<div *ngIf="permissionAction.delete">
  <div>
    <h5 class="text-primary">
      <nb-icon icon="settings-outline"></nb-icon> MANTENIMIENTO DATA ENTRY
    </h5>
  </div>
  <br />
  <div class="row">
    <div class="col-md-6">
      <ngx-delete-data-entry></ngx-delete-data-entry>
    </div>
  </div>
</div>
