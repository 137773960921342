import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IView } from '../../view/view.interface';
import { ITemplateExcelGet, ITemplate } from '../template-data-entry.interface';
import { ISettingAlert } from '../../../../utils/alert/alert.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { TemplateService } from '../template-data-entry.service';
import { ViewService } from '../../view/view.service';

@Component({
  selector: 'ngx-download-template-data-entry',
  templateUrl: './download-template-data-entry.component.html',
  styleUrls: ['./download-template-data-entry.component.scss'],
})
export class DownloadTemplateDataEntryComponent implements OnInit {
  fg = this.fb.group({
    viewId: ['', [Validators.required]],
    nameTemplate: ['', [Validators.required]],
  });

  views: Array<IView> = [];
  templates: Array<ITemplate> = [];
  templateView: Array<ITemplate> = [];
  loading: boolean = false;
  settingAlert: ISettingAlert = {};

  constructor(
    private fb: FormBuilder,
    private templateService: TemplateService,
    private viewService: ViewService
  ) {}

  async ngOnInit() {
    await this.getViews();
    await this.getTemplates();

    this.fg.controls['viewId'].valueChanges.subscribe((viewId: number) => {
      this.fg.controls['nameTemplate'].setValue('');
      if (this.views && this.templates && viewId) {
        this.templateView = this.templates.filter(
          (template) => viewId == template.viewId
        );
      }
    });
  }

  async onSubmitFg() {
    const template = this.fg.value as ITemplate;
    try {
      this.loading = true;
      const nameView = this.views.find(
        (view) => view.id === template.viewId
      ).nameView;
      const excel = await this.templateService.getTemplateExcelAsync({
        nameView: nameView,
        nameTemplate: template.nameTemplate,
      });
      this.downloadFile(excel);
      this.loading = false;
      this.settingAlert = excel;
      this.fg.reset();
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  downloadFile(excel: ITemplateExcelGet) {
    var a = document.createElement('a');
    a.href = 'data:application/xlsx;base64,' + excel.data;
    a.target = '_blank';
    a.download = `${excel.name}__${Date.now()}.xlsx`;
    a.click();
  }

  async getViews() {
    try {
      this.loading = true;
      this.views = await this.viewService.listViewsAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  async getTemplates() {
    try {
      this.loading = true;
      this.templates = await this.templateService.listTemplatesAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }
}
