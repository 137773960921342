import { Component, OnInit } from '@angular/core';
import { NbWindowRef, NbWindowService } from '@nebular/theme';
import { FormBuilder, Validators } from '@angular/forms';
import { TemplateService } from '../template-data-entry.service';
import { NewViewDataEntryComponent } from '../../view/new/new-view-data-entry.component';
import { IView } from '../../view/view.interface';
import {
  ITemplateGet,
  ITemplate,
  ITemplateExcel,
  ITableDataEntry,
} from '../template-data-entry.interface';
import { ISettingAlert } from '../../../../utils/alert/alert.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewService } from '../../view/view.service';
import { CreateTemplateValidator } from './create-template-data-entry.validator';
import { UtilXlsx } from '../../../../utils/xlsx';
import { IHeader } from '../../../../utils/xlsx/xlsx.interface';

@Component({
  selector: 'ngx-create-template-data-entry',
  templateUrl: './create-template-data-entry.component.html',
  styleUrls: ['./create-template-data-entry.component.scss'],
})
export class CreateTemplateDataEntryComponent implements OnInit {
  fg = this.fb.group({
    viewId: [],
    nameTemplate: [],
    nameTable: [],
  });
  file: File;
  views: Array<IView> = [];
  templates: Array<ITemplateGet> = [];
  tables: Array<ITableDataEntry> = [];
  loading: boolean = false;
  validFile: boolean = false;
  settingAlert: ISettingAlert = {};
  windowsRef: NbWindowRef = null;
  utilXlsx: UtilXlsx = new UtilXlsx();

  constructor(
    private fb: FormBuilder,
    private windowService: NbWindowService,
    private templateService: TemplateService,
    private viewService: ViewService
  ) {}

  async ngOnInit() {
    await this.getViews();
    await this.getTables();
    await this.getTemplates();
    this.setValidatorFg();
  }

  setValidatorFg() {
    this.fg = this.fb.group({
      viewId: ['', [Validators.required]],
      nameTemplate: ['', [Validators.required, Validators.minLength(5)]],
      nameTable: ['', [Validators.required, Validators.minLength(5)]],
    });
    this.fg.setValidators(
      CreateTemplateValidator.validateTemplate(this.templates)
    );
  }

  async onSubmitFg() {
    let template = this.fg.value as ITemplate;
    try {
      this.loading = true;
      await this.utilXlsx.setHeadersAndData(this.file);
      const columns = this.utilXlsx.getHeaders();
      this.existColumnsExcelInDataBase(columns);
      this.utilXlsx.isCorrectTemplate();
      template.columns = columns;
      await this.templateService.createTemplateAsync(template);
      const templateExcel = this.getNameViewAndTemplate(template);
      this.settingAlert = await this.templateService.uploadTemplateExcelAsync(
        this.file,
        templateExcel
      );
      this.loading = false;
      this.fg.reset();
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  onChangeNewTemplateExcel(event: any) {
    this.file = event.target.files[0];
    this.validFile = true;
  }

  getNameViewAndTemplate(template: ITemplate): ITemplateExcel {
    const nameView = this.views.find(
      (view) => view.id === template.viewId
    ).nameView;
    return {
      nameView,
      nameTemplate: template.nameTemplate,
    };
  }
  existColumnsExcelInDataBase(columns: IHeader[]) {
    const strColumns = JSON.stringify(columns);
    const exist = this.templates.find(
      (template) => strColumns === JSON.stringify(template.columns)
    );
    if (exist) {
      throw new HttpErrorResponse({
        error: {
          message: 'La plantilla excel ya existe, por favor verifique',
        },
        status: 404,
      });
    }
  }

  onNewView() {
    this.windowsRef = this.windowService.open(NewViewDataEntryComponent, {
      title: 'NUEVA VISTA',
      context: {},
      closeOnEsc: true,
    });
    this.windowsRef.onClose.subscribe(async () => {
      await this.getViews();
    });
  }

  getStatusFg(itemFg: string): string {
    if (!(this.fg.get(itemFg).dirty || this.fg.get(itemFg).touched)) {
      return 'info';
    }
    if (this.fg.get(itemFg).valid) {
      return 'success';
    }
    return 'danger';
  }

  async getViews() {
    try {
      this.loading = true;
      this.views = await this.viewService.listViewsAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  async getTables() {
    try {
      this.loading = true;
      this.tables = await this.templateService.listTablesAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  async getTemplates() {
    try {
      this.loading = true;
      this.templates = await this.templateService.listTemplatesAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }
}
