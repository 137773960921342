import { templateComponents } from './template';
import { DataEntryComponent } from './data-entry.component';
import { UploadDataEntryComponent } from './upload/upload-data-entry.component';
import { DeleteDataEntryComponent } from './delete/delete-data-entry.component';
import { viewComponents } from './view';

export const dataEntryComponents = [
  ...templateComponents,
  ...viewComponents,
  DataEntryComponent,
  UploadDataEntryComponent,
  DeleteDataEntryComponent,
];
