import { FormGroup, ValidatorFn } from '@angular/forms';

export class DeleteDataEntryValidator {
  constructor() {}

  static validateCode(): ValidatorFn {
    return (fg: FormGroup): null => {
      const ctrlCode = fg.controls['code'];
      const valueCole = String(ctrlCode.value);

      if (!valueCole.match(/^[0-9]*$/)) {
        ctrlCode.setErrors({
          notNumber: true,
        });
        return null;
      }
      if (!valueCole.match(/^[0-9]{12,15}$/)) {
        ctrlCode.setErrors({
          rangeNumber: true,
        });
        return null;
      }

      return null;
    };
  }
}
