export interface IBoardPage {
  name?: string;
  url: string;
  abrev: string;
}
interface IBoardView {
  [view: number]: {
    [codePage: number]: IBoardPage;
  };
}

export const BOARDS: IBoardView = {
  1: {
    1: {
      abrev: 'Agricultor',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNzMzZjgyN2MtNmM3NS00MDIzLThhYzQtYjE0YmVhZDM3NTBiIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9',
    },
  },
  2: {
    1: {
      name: 'Transferencia Cantidad Calidad',
      abrev: 'Cantidad-Calidad',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiMWRkYmM1YTctMzgyNi00ZTVkLTk4ODQtZjAxZTZmOWE1NzAxIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectiona22f9aacb90119ac246e',
    },
    2: {
      name: 'Transferencia Programacion Despacho y Control de Rutas',
      abrev: 'Prog Des-Ctrl Cam',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiMWRkYmM1YTctMzgyNi00ZTVkLTk4ODQtZjAxZTZmOWE1NzAxIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectiona392dd9aa2671c15a740',
    },
    3: {
      name: 'Transporte En Transito-Estadia',
      abrev: 'Estadia Transporte',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiMWRkYmM1YTctMzgyNi00ZTVkLTk4ODQtZjAxZTZmOWE1NzAxIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectiondd2d0da8911d71c8d0eb',
    },
    4: {
      name: 'Recepcion Cantidad Calidad',
      abrev: 'Cantidad-Calidad',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiMWRkYmM1YTctMzgyNi00ZTVkLTk4ODQtZjAxZTZmOWE1NzAxIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectione5ce9f280394eeb2c9b7',
    },
    5: {
      name: 'Recepcion Programacion-Control Camiones',
      abrev: 'Prog Des-Ctrl Cam',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiMWRkYmM1YTctMzgyNi00ZTVkLTk4ODQtZjAxZTZmOWE1NzAxIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectiond92620d06e5879430b0d',
    },
    6: {
      name: 'Stock Materia Prima',
      abrev: 'Stock Materia Prima',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiMWRkYmM1YTctMzgyNi00ZTVkLTk4ODQtZjAxZTZmOWE1NzAxIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectioncd34a620ad0332e071b2',
    },
    7: {
      name: 'Stock Materia Prima Tiempo Llenado',
      abrev: 'Stock Tiempo LLen',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiMWRkYmM1YTctMzgyNi00ZTVkLTk4ODQtZjAxZTZmOWE1NzAxIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection50373e55d78d01da8519',
    },
  },
  3: {
    1: {
      name: 'Cola Camiones En Descarga-Carga',
      abrev: 'Cola Cam Descargados',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiYmM3NTg3ODItMGE4Yi00MGNiLWJjYTQtN2IxYjFmZDZkZmQ2IiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection03b7d54804d948e71442',
    },
    2: {
      name: 'Nro Camiones Descargados',
      abrev: 'Nro Cam Descargados',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiYmM3NTg3ODItMGE4Yi00MGNiLWJjYTQtN2IxYjFmZDZkZmQ2IiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection3ca409e8783805411120',
    },
    3: {
      name: 'Ritmo Entrada-Salida',
      abrev: 'Ritmo Entrada-Cam',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiYmM3NTg3ODItMGE4Yi00MGNiLWJjYTQtN2IxYjFmZDZkZmQ2IiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection78bc622a0bde57014290',
    },
  },
  4: {
    1: {
      abrev: 'Planificación',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNzUwOWYzOTAtM2YzNy00NTQ3LWFhMzctOGVlNWQwMzlkMmJmIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection6926e62d560c7630a41e',
    },
    2: {
      abrev: 'Recepción',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNzUwOWYzOTAtM2YzNy00NTQ3LWFhMzctOGVlNWQwMzlkMmJmIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectionb761698c9070a8930e05',
    },
    3: {
      abrev: 'Transferencia',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNzUwOWYzOTAtM2YzNy00NTQ3LWFhMzctOGVlNWQwMzlkMmJmIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectiona2ec01c0de420d070727',
    },
    4: {
      abrev: 'Calidad de Recepción',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNzUwOWYzOTAtM2YzNy00NTQ3LWFhMzctOGVlNWQwMzlkMmJmIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectione8408b7041003689d735',
    },
    5: {
      abrev: 'Calidad de Transferencia',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNzUwOWYzOTAtM2YzNy00NTQ3LWFhMzctOGVlNWQwMzlkMmJmIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectionfe26303cb914dbc99b0e',
    },
    6: {
      abrev: 'Resumen de Stock',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNzUwOWYzOTAtM2YzNy00NTQ3LWFhMzctOGVlNWQwMzlkMmJmIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection8cb1f81797364a7b894e',
    },
    7: {
      abrev: 'Stock por Silos',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNzUwOWYzOTAtM2YzNy00NTQ3LWFhMzctOGVlNWQwMzlkMmJmIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection2baa25dd563d31020e24',
    },
  },
  5: {
    1: {
      name: 'Cola Camiones En Descarga-Carga',
      abrev: 'Cola Cam En Carga',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiYmM3NTg3ODItMGE4Yi00MGNiLWJjYTQtN2IxYjFmZDZkZmQ2IiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection03b7d54804d948e71442',
    },
    2: {
      name: 'Ritmo Entrada-Salida',
      abrev: 'Ritmo Salida Cam',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiYmM3NTg3ODItMGE4Yi00MGNiLWJjYTQtN2IxYjFmZDZkZmQ2IiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection78bc622a0bde57014290',
    },
  },
  6: {
    1: {
      abrev: 'Propio',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection9693f0719dedb5a03181',
    },
    2: {
      abrev: 'Propio 2',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection6234b5e1a758d408b04e',
    },
    3: {
      abrev: 'Tercero',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection2d93349f1d20248a9e2c',
    },
    4: {
      abrev: 'Tercero 2',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectionea32691803781a173475',
    },
    5: {
      abrev: 'Tercero 3',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection39f57a2b0e059818eb01',
    },
    6: {
      abrev: 'TM - PPI/PDF',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectionb60e831cba94951c0000',
    },
    7: {
      abrev: 'TM 2 - PPI/PDF',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectiond51b251890a5c07cde38',
    },
    8: {
      abrev: 'TM 3 - PPI/PDF',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectionaa38500bb7000b848a77',
    },
    9: {
      abrev: 'TM',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection6be59341e37da7419e7b',
    },
    10: {
      abrev: 'TM 2',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection1f727cc447e041000e08',
    },
    11: {
      abrev: 'TM 3',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSection9f9aa89d9a2c23013004',
    },
    12: {
      abrev: 'En transito',
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVjZGIwNDgtNDIyZS00MDUwLTgzMDMtMzU3NjFiOGNlYjMwIiwidCI6IjNiMTY2MTZkLWYxNzQtNDk3YS1hMWFlLTkwMGQ4NTEwNjk5NiJ9&pageName=ReportSectiond815db0760e3378f3f91',
    },
  },
};
