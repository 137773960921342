import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Powered by
      <b
        ><a href="https://protecso.la/" target="_blank"
          ><img
            src="../../../../assets/images/protecso.webp"
            alt="logo"
            width="150"
            height="60" /></a
      ></b>
      2021
    </span>
    <div class="socials">
      <!--a href="#" target="_blank" class="ion ion-social-github"></a-->
      <a
        href="https://www.facebook.com/Protecso/"
        target="_blank"
        class="ion ion-social-facebook"
      ></a>
      <!--a href="#" target="_blank" class="ion ion-social-twitter"></a-->
      <a
        href="https://www.linkedin.com/company/protecso-usa"
        target="_blank"
        class="ion ion-social-linkedin"
      ></a>
    </div>
  `,
})
export class FooterComponent {}
