import { FormGroup, ValidatorFn } from '@angular/forms';
import { ITemplate } from '../template-data-entry.interface';

export class CreateTemplateValidator {
  constructor() {}

  static validateTemplate(templates: Array<ITemplate>): ValidatorFn {
    return (fg: FormGroup): null => {
      const ctrlNameTemplate = fg.controls['nameTemplate'];
      const nameTemplate = templates.find(
        (template) => template.nameTemplate === ctrlNameTemplate.value
      );
      if (nameTemplate) {
        ctrlNameTemplate.setErrors({
          alreadyExists: true,
        });
      }

      const ctrlNameTable = fg.controls['nameTable'];
      const nameTable = templates.find(
        (template) => template.nameTable === ctrlNameTable.value
      );
      if (nameTable) {
        ctrlNameTable.setErrors({
          alreadyExists: true,
        });
      }
      return null;
    };
  }
}
