import { Component } from '@angular/core';
import { IUser } from './user.interface';

@Component({
  selector: 'ngx-user',
  templateUrl: './user.component.html',
  styleUrls: [],
})
export class UserComponent {
  reloadUsers: boolean = false;
  userSelected: IUser = null;
  constructor() {}

  onSetUserToUpdate(user: IUser) {
    this.userSelected = user;
  }
  onCloseUserUpdate() {
    this.userSelected = null;
  }
  onUserUpdated() {
    this.reloadUsers = true;
  }
  onUserCreated() {
    this.reloadUsers = true;
  }
}
