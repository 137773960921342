import { Injectable } from '@angular/core';
import { ControlTowerService } from '../../control-tower.service';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../../middleware/token/token.service';
import {
  IUser,
  IUserGet,
  IUserUpdate,
  IResponseSetUser,
} from './user.interface';
const ROUTES = {
  USER: '/user',
};
@Injectable({
  providedIn: 'root',
})
export class UserService extends ControlTowerService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  async listUsersAsync(): Promise<Array<IUserGet>> {
    const response = await this.request('get', ROUTES.USER).toPromise();
    return response.body.users;
  }

  async createUserAsync(user: IUser): Promise<IResponseSetUser> {
    const response = await this.request('post', ROUTES.USER, user).toPromise();
    return {
      message: response.body.message,
      status: response.status,
    };
  }

  async updateUserAsync(user: IUserUpdate): Promise<IResponseSetUser> {
    const response = await this.request('put', ROUTES.USER, user).toPromise();
    return {
      message: response.body.message,
      status: response.status,
    };
  }
}
