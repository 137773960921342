import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ControlTowerService } from '../../../control-tower.service';
import { TokenService } from '../../../../middleware/token/token.service';
import { IResponseCreateView, IView } from './view.interface';

const ROUTES = {
  VIEW: '/data-entry/view',
};

@Injectable({
  providedIn: 'root',
})
export class ViewService extends ControlTowerService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  async listViewsAsync(): Promise<Array<IView>> {
    const response = await this.request('get', ROUTES.VIEW).toPromise();
    return response.body.views;
  }

  async saveViewAsync(newView: IView): Promise<IResponseCreateView> {
    const response = await this.request(
      'post',
      ROUTES.VIEW,
      newView
    ).toPromise();
    return {
      message: response.body.message,
      status: response.status,
    };
  }
}
