<nb-card [nbSpinner]="loading" nbSpinnerStatus="info">
  <nb-card-body>
    <ngx-alert [setting]="settingAlert"></ngx-alert>
    <form [formGroup]="fg" (ngSubmit)="onSubmit()">
      <div class="form-group row">
        <label for="inputPassword" class="label col-sm-3 col-form-label"
          >Nueva Contraseña</label
        >
        <div class="col-sm-9">
          <input
            type="password"
            nbInput
            fullWidth
            [status]="getStatusFg('password')"
            id="inputPassword"
            placeholder="Nueva Contraseña"
            formControlName="password"
          />
          <div
            *ngIf="
              fg.get('password').invalid &&
              (fg.get('password').dirty || fg.get('password').touched)
            "
            class="text-danger"
          >
            <div *ngIf="fg.get('password').errors?.required">
              No puede quedar vacio.
            </div>
            <div *ngIf="fg.get('password').errors?.minlength">
              Debe tener como minimo 5 caracteres.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputRepeatPassword" class="label col-sm-3 col-form-label"
          >Repetir Contraseña</label
        >
        <div class="col-sm-9">
          <input
            type="password"
            nbInput
            fullWidth
            [status]="getStatusFg('repeatPassword')"
            id="inputRepeatPassword"
            placeholder="Repetir Contraseña"
            formControlName="repeatPassword"
          />
          <div
            *ngIf="
              fg.get('repeatPassword').invalid &&
              (fg.get('repeatPassword').dirty ||
                fg.get('repeatPassword').touched)
            "
            class="text-danger"
          >
            <div *ngIf="fg.get('repeatPassword').errors?.required">
              No puede quedar vacio.
            </div>
            <div *ngIf="fg.get('repeatPassword').errors?.notEqual">
              Contraseñas no son iguales.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="offset-sm-3 col-sm-9">
          <button
            type="submit"
            nbButton
            outline
            status="success"
            [disabled]="fg.invalid"
          >
            GUARDAR
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
