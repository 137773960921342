<button
  nbButton
  size="small"
  shape="semi-round"
  status="basic"
  [disabled]="!permitted"
  (click)="onEmitCodePageSelecte()"
>
  {{ page.abrev }}
</button>
