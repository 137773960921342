import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ISettingAlert } from '../../../utils/alert/alert.interface';
import { IUser } from '../user.interface';
import { UserService } from '../user.service';

type TActionTable = 'select-user';

@Component({
  selector: 'ngx-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
})
export class ListUserComponent implements OnInit, OnChanges {
  @Output() setUser = new EventEmitter<any>();
  @Input() reloadUsers: boolean = false;
  user: IUser;
  settings: any;
  data: any;
  showTable = false;
  loading = false;
  users: Array<IUser> = [];
  settingAlert: ISettingAlert = {};

  constructor(private userService: UserService) {}

  async ngOnChanges(changes: SimpleChanges) {
    const reloadUsers = changes.reloadUsers.currentValue;
    if (reloadUsers) {
      await this.getUsers();
    }
  }

  async ngOnInit() {
    this.settings = {
      actions: {
        columnTitle: 'Acciones',
        add: false,
        edit: false,
        delete: false,
        position: 'left',
        width: '5%',
        custom: [
          {
            name: 'select-user',
            title: '<i class="nb-edit"></i>',
          },
        ],
      },
      noDataMessage: 'Sin registros para mostrar',
      columns: {
        email: {
          title: 'Usuarios',
          width: '90%',
        },
      },
    };
    await this.reloadData();
    this.showTable = true;
  }

  async reloadData() {
    await this.getUsers();
  }

  async getUsers() {
    try {
      this.loading = true;
      this.data = await this.userService.listUsersAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  onActionTable(event) {
    const action: TActionTable = event.action;
    switch (action) {
      case 'select-user':
        this.user = event.data as IUser;
        this.setUser.emit(this.user);
        break;
    }
  }
}
