<nb-card [nbSpinner]="loading" *ngIf="showTable">
  <nb-card-header class="text-primary">
    <nb-icon icon="people-outline"></nb-icon>
    <strong> USUARIOS </strong>
  </nb-card-header>
  <nb-card-body>
    <ng2-smart-table
      [settings]="settings"
      [source]="data"
      (custom)="onActionTable($event)"
    >
    </ng2-smart-table>
  </nb-card-body>
</nb-card>
