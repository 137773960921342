import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ISettingAlert } from './alert.interface';

@Component({
  selector: 'ngx-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit, OnChanges {
  @Input() setting: ISettingAlert;
  display: boolean = false;
  status: number;
  message: string;

  constructor() {}
  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    const currentValue = changes.setting.currentValue;
    this.status = currentValue.status;
    if (this.status) {
      if (this.status < 300) {
        this.message = currentValue.message;
      }
      if (this.status > 300) {
        this.message = currentValue.error.message;
      }
      this.display = true;
      setTimeout(() => (this.display = false), 5000);
    } else if (this.status == 0) {
      this.message = 'El servicio no esta disponible';
      this.display = true;
    }
  }
  getColorStatus(): string {
    const status = this.status;
    if (status >= 100 && status < 200) {
      return 'info';
    } else if (status >= 200 && status < 300) {
      return 'success';
    } else if (status >= 300 && status < 400) {
      return 'warning';
    } else if (status >= 400 && status < 500) {
      return 'danger';
    } else if (status >= 500) {
      return 'danger';
    } else if (status < 100) {
      return 'danger';
    }
  }
  getColorText(): string {
    return 'text-' + this.getColorStatus();
  }
  onClose() {
    this.display = false;
  }
}
