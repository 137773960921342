import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataEntryService } from '../data-entry.service';
import { NbTagComponent } from '@nebular/theme';
import { TemplateService } from '../template/template-data-entry.service';
import { ViewService } from '../view/view.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IView } from '../view/view.interface';
import { ITemplate } from '../template/template-data-entry.interface';
import { ISettingAlert } from '../../../utils/alert/alert.interface';
import { UtilXlsx } from '../../../utils/xlsx';
import { IHeader } from '../../../utils/xlsx/xlsx.interface';

@Component({
  selector: 'ngx-upload-data-entry',
  templateUrl: './upload-data-entry.component.html',
  styleUrls: ['./upload-data-entry.component.scss'],
})
export class UploadDataEntryComponent implements OnInit {
  files: Array<File> = [];
  filesNames: Array<string> = [];
  filesNamesSuccessUploaded: Array<string> = [];
  filesNamesFailedUploaded: Array<string> = [];
  fileNameProcessing: string = '';
  uploading: boolean = false;
  loading: boolean = false;
  readyToUpload: boolean = false;
  views: Array<IView> = [];
  templates: Array<ITemplate> = [];
  settingAlert: ISettingAlert = {};
  utilXlsx: UtilXlsx = new UtilXlsx();
  constructor(
    private dataEntryService: DataEntryService,
    private templateService: TemplateService,
    private viewService: ViewService
  ) {}

  async ngOnInit() {
    await this.getViews();
    await this.getTemplates();
  }

  onChangeNewDataEntry(event): void {
    const chosenFiles = event.target.files as FileList;
    const filesToFilter: Array<File> = [];
    for (const i in Array.from(Array(chosenFiles.length).keys())) {
      filesToFilter.push(chosenFiles[i]);
    }
    const newFiles = filesToFilter.filter(
      (chosenFile: File) => !this.filesNames.includes(chosenFile.name)
    );
    this.files = this.files.concat(newFiles);
    this.filesNames = this.filesNames.concat(
      newFiles.map((newFile: File) => newFile.name)
    );
    this.readyToUpload = this.filesNames.length > 0 ? true : false;
  }

  onTagRemoveReady(tagToRemove: NbTagComponent): void {
    this.files = this.files.filter((file) => file.name !== tagToRemove.text);
    this.filesNames = this.filesNames.filter(
      (name) => name !== tagToRemove.text
    );
    this.readyToUpload = this.filesNames.length > 0 ? true : false;
  }

  onTagRemoveSuccess(tagToRemove: NbTagComponent): void {
    this.filesNamesSuccessUploaded = this.filesNamesSuccessUploaded.filter(
      (name) => name !== tagToRemove.text
    );
  }

  onTagRemoveFailed(tagToRemove: NbTagComponent): void {
    this.filesNamesFailedUploaded = this.filesNamesFailedUploaded.filter(
      (name) => name !== tagToRemove.text
    );
  }

  async onUploadNewDataEntry(): Promise<void> {
    this.readyToUpload = false;
    this.uploading = true;
    for (const file of this.files) {
      this.fileNameProcessing = this.filesNames.shift();
      const isCorret = this.isCorrectFileName(this.fileNameProcessing);
      if (!isCorret) {
        continue;
      }
      try {
        await this.utilXlsx.setHeadersAndData(file);
        const columns = this.utilXlsx.getHeaders();
        this.isColumnEqualToTemplate(columns);
        this.utilXlsx.validateData();
        await this.dataEntryService.uploadExcelAsync(file);
        this.filesNamesSuccessUploaded.push(this.fileNameProcessing);
      } catch (err) {
        const e = err as HttpErrorResponse;
        this.filesNamesFailedUploaded.push(
          this.fileNameProcessing + ': ' + e.error.message
        );
      }
    }
    this.files = [];
    this.uploading = false;
  }

  getNameViewTemplateCode = (fileName: string): any => {
    const [nameView, nameTemplate, nameCode] = fileName
      .split('.xlsx')[0]
      .split('__');
    return { nameView, nameTemplate, nameCode };
  };

  isCorrectFileName(fileName: string): boolean {
    const { nameView, nameTemplate, nameCode } =
      this.getNameViewTemplateCode(fileName);
    if (!nameView || !nameTemplate || !nameCode) {
      this.filesNamesFailedUploaded.push(
        this.fileNameProcessing +
          ' - Nombre de archivo incorrecto, no tiene la estructura correcta'
      );
      return false;
    }
    if (!this.views.find((_view) => _view.nameView === nameView)) {
      this.filesNamesFailedUploaded.push(
        this.fileNameProcessing +
          ' - Nombre incorrecto, no contiene una vista registrada'
      );
      return false;
    }
    if (
      !this.templates.find(
        (_template) => _template.nameTemplate === nameTemplate
      )
    ) {
      this.filesNamesFailedUploaded.push(
        this.fileNameProcessing +
          ' - Nombre incorrecto, no contiene una plantilla registrada'
      );
      return false;
    }
    if (typeof Number(nameCode) !== 'number') {
      this.filesNamesFailedUploaded.push(
        this.fileNameProcessing +
          ' - Nombre incorrecto, no contiene un código numérico'
      );
    }
    if (Number(nameCode) > Date.now()) {
      this.filesNamesFailedUploaded.push(
        this.fileNameProcessing +
          ' - Nombre incorrecto, el código es incorrecto'
      );
      return false;
    }
    return true;
  }

  isColumnEqualToTemplate(columns: IHeader[]) {
    const { nameTemplate } = this.getNameViewTemplateCode(
      this.fileNameProcessing
    );
    const columnsTemplate = this.templates.find(
      (template) => template.nameTemplate === nameTemplate
    ).columns;
    if (!(JSON.stringify(columns) === JSON.stringify(columnsTemplate))) {
      throw new HttpErrorResponse({
        error: {
          message:
            'Columnas incorrectas, no coinciden con las columnas de la plantilla',
        },
        status: 404,
      });
    }
  }

  async getViews() {
    try {
      this.loading = true;
      this.views = await this.viewService.listViewsAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  async getTemplates() {
    try {
      this.loading = true;
      this.templates = await this.templateService.listTemplatesAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }
}
