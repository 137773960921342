<nb-card>
  <nb-card-body>
    <ngx-alert [setting]="settingAlert"></ngx-alert>
    <div class="row">
      <div class="col-md-3">
        <nb-card [nbSpinner]="loading" nbSpinnerStatus="info">
          <nb-card-header class="text-primary">
            SELECCIONAR ARCHIVOS
          </nb-card-header>
          <nb-card-body>
            <input
              type="file"
              nbInput
              fullWidth
              multiple
              accept=".xlsx"
              (change)="onChangeNewDataEntry($event)"
            />
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-9">
        <nb-card [nbSpinner]="uploading" nbSpinnerStatus="info">
          <nb-card-header class="text-primary">
            LISTOS PARA SUBIR
          </nb-card-header>
          <nb-card-body>
            <nb-tag-list (tagRemove)="onTagRemoveReady($event)">
              <nb-tag
                removable
                appearance="outline"
                *ngFor="let fileName of filesNames"
                [text]="fileName"
                status="info"
              ></nb-tag>
            </nb-tag-list>
          </nb-card-body>
          <nb-card-footer>
            <div class="buttons-row" style="text-align: center">
              <button
                nbButton
                outline
                status="success"
                (click)="onUploadNewDataEntry()"
                [disabled]="!readyToUpload"
              >
                <nb-icon icon="cloud-upload-outline"></nb-icon>
                SUBIR
              </button>
            </div>
          </nb-card-footer>
        </nb-card>
      </div>
    </div>
    <div class="row" *ngIf="uploading">
      <div class="col-md-12">
        <nb-alert outline="primary" class="text-primary"
          >Subiendo {{ fileNameProcessing }} ...
        </nb-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <nb-card>
          <nb-card-header class="text-success">
            <nb-icon icon="check-circle" pack="fas"></nb-icon>
            SUBIDOS EXITOSAMENTE
          </nb-card-header>
          <nb-card-body>
            <nb-tag-list (tagRemove)="onTagRemoveSuccess($event)">
              <nb-tag
                removable
                appearance="outline"
                *ngFor="let fileName of filesNamesSuccessUploaded"
                [text]="fileName"
                status="success"
              ></nb-tag>
            </nb-tag-list>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6">
        <nb-card>
          <nb-card-header class="text-danger">
            <nb-icon icon="close-circle-outline"></nb-icon>
            SUBIDOS FALLIDOS
          </nb-card-header>
          <nb-card-body>
            <nb-tag-list (tagRemove)="onTagRemoveFailed($event)">
              <nb-tag
                removable
                appearance="outline"
                *ngFor="let fileName of filesNamesFailedUploaded"
                [text]="fileName"
                status="danger"
              ></nb-tag>
            </nb-tag-list>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </nb-card-body>
</nb-card>
