import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ControlTowerService } from '../../../control-tower.service';
import { TokenService } from '../../../../middleware/token/token.service';
import {
  ITemplateExcelGet,
  IResponseUploadTemplate,
  ITemplateExcel,
  IResponseCreateTemplate,
  ITemplate,
  ITemplateGet,
  ITableDataEntry,
} from './template-data-entry.interface';

const ROUTES = {
  TEMPLATE: '/data-entry/template',
  TEMPLATE_EXCEL: '/data-entry/template/excel/:view/:template',
  TEMPLATE_TABLE: '/data-entry/template/table',
};

@Injectable({
  providedIn: 'root',
})
export class TemplateService extends ControlTowerService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  async listTemplatesAsync(): Promise<Array<ITemplateGet>> {
    const response = await this.request('get', ROUTES.TEMPLATE).toPromise();
    return response.body.templates;
  }

  async listTablesAsync(): Promise<Array<ITableDataEntry>> {
    const response = await this.request(
      'get',
      ROUTES.TEMPLATE_TABLE
    ).toPromise();
    return response.body.tables;
  }

  async createTemplateAsync(
    template: ITemplate
  ): Promise<IResponseCreateTemplate> {
    const response = await this.request(
      'post',
      ROUTES.TEMPLATE,
      template
    ).toPromise();
    return {
      message: response.body.message,
      status: response.status,
    };
  }

  async uploadTemplateExcelAsync(
    file: File,
    excel: ITemplateExcel
  ): Promise<IResponseUploadTemplate> {
    const { nameView, nameTemplate } = excel;
    const formData = new FormData();
    formData.append('templateExcel', file);
    const response = await this.request(
      'post',
      ROUTES.TEMPLATE_EXCEL.replace(':view', String(nameView)).replace(
        ':template',
        String(nameTemplate)
      ),
      formData
    ).toPromise();
    return {
      message: response.body.message,
      status: response.status,
    };
  }

  async getTemplateExcelAsync(
    excel: ITemplateExcel
  ): Promise<ITemplateExcelGet> {
    const { nameView, nameTemplate } = excel;
    const response = await this.request(
      'get',
      ROUTES.TEMPLATE_EXCEL.replace(':view', String(nameView)).replace(
        ':template',
        String(nameTemplate)
      )
    ).toPromise();
    return {
      data: response.body.data,
      name: response.body.name,
      message: response.body.message,
      status: response.status,
    };
  }
}
