import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ViewService } from '../view.service';
import { IView } from '../view.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ISettingAlert } from '../../../../utils/alert/alert.interface';
import { NewViewValidator } from './new-view-data-entry.validator';
@Component({
  selector: 'ngx-new-view-data-entry',
  templateUrl: './new-view-data-entry.component.html',
  styleUrls: ['./new-view-data-entry.component.scss'],
})
export class NewViewDataEntryComponent implements OnInit {
  fg = this.fb.group({
    nameView: [],
    nameTableDataBase: [],
  });
  loading = false;
  views: Array<IView> = [];
  settingAlert: ISettingAlert = {};
  constructor(private fb: FormBuilder, private viewService: ViewService) {}

  async ngOnInit() {
    await this.getViews();

    this.fg = this.fb.group({
      nameView: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          NewViewValidator.nameViewExisting(this.views),
        ],
      ],
    });
  }

  async onSubmitFg() {
    const newView = this.fg.value as IView;
    try {
      this.loading = true;
      const response = await this.viewService.saveViewAsync(newView);
      this.loading = false;
      this.settingAlert = response;
      this.fg.reset();
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  getStatusFg(itemFg: string): string {
    if (!(this.fg.get(itemFg).dirty || this.fg.get(itemFg).touched)) {
      return 'info';
    }
    if (this.fg.get(itemFg).valid) {
      return 'success';
    }
    return 'danger';
  }

  async getViews() {
    try {
      this.loading = true;
      this.views = await this.viewService.listViewsAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }
}
