<div class="row">
  <div class="col-md-10">
    <h5 class="text-primary">
      <nb-icon icon="bar-chart"></nb-icon> TABLEROS POWER BI
    </h5>
  </div>
  <div class="col-md-2" style="padding-left: 2em">
    <button
      nbButton
      status="warning"
      shape="semi-round"
      outline
      [disabled]="!url"
      (click)="onReturnHome()"
    >
      <nb-icon icon="home-outline"></nb-icon> Inicio
    </button>
  </div>
</div>
<br />
<nb-card>
  <nb-card-body>
    <div class="row" *ngIf="!url">
      <div class="col-md-2" id="logo-tower">
        <div class="row">
          <div
            class="col-md-8"
            style="padding-bottom: 65%; padding-top: 10%; padding-left: 10%"
          >
            <img
              class="responsive"
              src="./../../../assets/images/logo-alicorp.png"
            />
          </div>
          <div class="col-md-7" style="padding-bottom: 10%; padding-left: 15%">
            <img
              style="border-radius: 10% 10% 10% 10%"
              class="responsive"
              src="./../../../assets/images/logo-head.png"
            />
          </div>
          <div class="col-md-10" style="padding-bottom: 75%; padding-left: 15%">
            <h4 style="color: #55703c">TORRE DE CONTROL</h4>
          </div>

          <div class="col-md-9" style="padding-top: 60%; padding-left: 10%">
            <b
              >Powered by
              <a href="https://protecso.la/" target="_blank"
                ><img
                  class="responsive"
                  src="../../../../assets/images/protecso.webp" /></a
            ></b>
          </div>
        </div>
      </div>
      <div class="col-md-10" id="views">
        <div class="row" id="views-1-3-5">
          <div class="col-md-4" id="view-1">
            <nb-card class="view">
              <nb-card-header class="header"> 1. Agricultor </nb-card-header>
              <nb-card-body>
                <img
                  class="center-image"
                  src="./../../../assets/images/iconos-alicorp/farmer.png"
                  style="width: 20%; height: 20%"
                />
                <nb-list>
                  <nb-list-item *ngFor="let view of views[1]" class="center">
                    <ngx-button-page
                      [codeViewPage]="view"
                      (sendUrlPageSelected)="onSetUrl($event)"
                    ></ngx-button-page>
                  </nb-list-item>
                </nb-list>
              </nb-card-body>
            </nb-card>
          </div>
          <div class="col-md-4" id="view-3">
            <nb-card class="view">
              <nb-card-header class="header">
                3. Log Abastecimiento
              </nb-card-header>
              <nb-card-body>
                <img
                  class="center-image"
                  src="./../../../assets/images/iconos-alicorp/supply-chain.png"
                  style="width: 20%; height: 20%"
                />
                <nb-list>
                  <nb-list-item *ngFor="let view of views[3]" class="center">
                    <ngx-button-page
                      [codeViewPage]="view"
                      (sendUrlPageSelected)="onSetUrl($event)"
                    ></ngx-button-page>
                  </nb-list-item>
                </nb-list>
              </nb-card-body>
            </nb-card>
          </div>
          <div class="col-md-4" id="view-5">
            <nb-card class="view">
              <nb-card-header class="header"> 5. Log Salida </nb-card-header>
              <nb-card-body>
                <img
                  class="center-image"
                  src="./../../../assets/images/iconos-alicorp/wheelbarrow.png"
                  style="width: 20%; height: 20%"
                />
                <nb-list>
                  <nb-list-item *ngFor="let view of views[5]" class="center">
                    <ngx-button-page
                      [codeViewPage]="view"
                      (sendUrlPageSelected)="onSetUrl($event)"
                    ></ngx-button-page>
                  </nb-list-item>
                </nb-list>
              </nb-card-body>
            </nb-card>
          </div>
        </div>
        <div class="row" id="views-2-4-6">
          <div class="col-md-4" id="view-2">
            <nb-card class="view">
              <nb-card-header class="header"> 2. Stock Grano </nb-card-header>
              <nb-card-body>
                <div class="row" id="transferencia">
                  <img
                    class="center-image"
                    src="./../../../assets/images/iconos-alicorp/truck.png"
                    style="width: 20%; height: 20%; padding-left: 5%"
                  />
                  <nb-list>
                    <nb-list-item
                      *ngFor="let view of views[2].transferencia"
                      class="center"
                    >
                      <ngx-button-page
                        [codeViewPage]="view"
                        (sendUrlPageSelected)="onSetUrl($event)"
                      ></ngx-button-page>
                    </nb-list-item>
                  </nb-list>
                </div>
                <div class="row" id="recepcion">
                  <img
                    class="center-image"
                    src="./../../../assets/images/iconos-alicorp/truck_01.png"
                    style="width: 20%; height: 20%; padding-left: 5%"
                  />
                  <nb-list>
                    <nb-list-item
                      *ngFor="let view of views[2].recepcion"
                      class="center"
                    >
                      <ngx-button-page
                        [codeViewPage]="view"
                        (sendUrlPageSelected)="onSetUrl($event)"
                      ></ngx-button-page>
                    </nb-list-item>
                  </nb-list>
                </div>

                <div class="row" id="stock">
                  <img
                    class="center-image"
                    src="./../../../assets/images/iconos-alicorp/warehouse.png"
                    style="width: 20%; height: 20%; padding-left: 5%"
                  />
                  <nb-list>
                    <nb-list-item
                      *ngFor="let view of views[2].stock"
                      class="center"
                    >
                      <ngx-button-page
                        [codeViewPage]="view"
                        (sendUrlPageSelected)="onSetUrl($event)"
                      ></ngx-button-page>
                    </nb-list-item>
                  </nb-list>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
          <div class="col-md-4" id="view-4">
            <nb-card class="view">
              <nb-card-header class="header">
                4. Recep y Transf
              </nb-card-header>
              <nb-card-body>
                <img
                  class="center-image"
                  src="./../../../assets/images/iconos-alicorp/data-transfer.png"
                  style="width: 20%; height: 20%"
                />
                <nb-list>
                  <nb-list-item *ngFor="let view of views[4]" class="center">
                    <ngx-button-page
                      [codeViewPage]="view"
                      (sendUrlPageSelected)="onSetUrl($event)"
                    ></ngx-button-page>
                  </nb-list-item>
                </nb-list>
              </nb-card-body>
            </nb-card>
          </div>
          <div class="col-md-4" id="view-6">
            <nb-card class="view">
              <nb-card-header class="header">
                6. Stock Producto Term
              </nb-card-header>
              <nb-card-body>
                <div class="row">
                  <img
                    class="center-image"
                    src="./../../../assets/images/iconos-alicorp/shelf.png"
                    style="display: inline"
                    style="width: 20%; height: 20%"
                  />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <nb-list>
                      <nb-list-item *ngFor="let view of views[6].propio">
                        <ngx-button-page
                          [codeViewPage]="view"
                          (sendUrlPageSelected)="onSetUrl($event)"
                        ></ngx-button-page>
                      </nb-list-item>
                    </nb-list>
                  </div>
                  <div class="col-md-6">
                    <nb-list>
                      <nb-list-item *ngFor="let view of views[6].tercero">
                        <ngx-button-page
                          [codeViewPage]="view"
                          (sendUrlPageSelected)="onSetUrl($event)"
                        ></ngx-button-page>
                      </nb-list-item>
                    </nb-list>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <nb-list>
                      <nb-list-item *ngFor="let view of views[6].tmPpi">
                        <ngx-button-page
                          [codeViewPage]="view"
                          (sendUrlPageSelected)="onSetUrl($event)"
                        ></ngx-button-page>
                      </nb-list-item>
                    </nb-list>
                  </div>
                  <div class="col-md-6">
                    <nb-list>
                      <nb-list-item *ngFor="let view of views[6].tm">
                        <ngx-button-page
                          [codeViewPage]="view"
                          (sendUrlPageSelected)="onSetUrl($event)"
                        ></ngx-button-page>
                      </nb-list-item>
                    </nb-list>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <nb-list>
                      <nb-list-item *ngFor="let view of views[6].transito">
                        <ngx-button-page
                          [codeViewPage]="view"
                          (sendUrlPageSelected)="onSetUrl($event)"
                        ></ngx-button-page>
                      </nb-list-item>
                    </nb-list>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <img class="responsive" src="./../../../assets/images/footer.png" />
      </div>
    </div>

    <div class="embed-container" *ngIf="url">
      <iframe
        class="embed-iframe"
        title="Productivo Tableros-TorreControl-Alicorp"
        [src]="url | safe"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  </nb-card-body>
</nb-card>
