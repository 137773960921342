import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbCardModule,
  NbButtonModule,
  NbIconModule,
  NbInputModule,
  NbTreeGridModule,
  NbStepperModule,
  NbAlertModule,
  NbSelectModule,
  NbSpinnerModule,
  NbDatepickerModule,
  NbListModule,
  NbTabsetModule,
  NbRadioModule,
  NbTagModule,
  NbCheckboxModule,
  NbToggleModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { managementComponents } from './mangement/management.components';
import { authComponents } from './auth/auth.components';
import { utilsComponents } from './utils/components.componets';
import { boardsBIComponents } from './boardsBI/boardsBI.components';

import { SafePipe } from './pipes/safe.pipe';
const controlTowerComponents = [
  ...managementComponents,
  ...utilsComponents,
  ...authComponents,
  ...boardsBIComponents,
];

const pipes = [SafePipe];

@NgModule({
  imports: [
    CommonModule,
    NbCardModule,
    NbTreeGridModule,
    NbButtonModule,
    NbIconModule,
    NbInputModule,
    NbStepperModule,
    Ng2SmartTableModule,
    NbAlertModule,
    NbSelectModule,
    NbSpinnerModule,
    NbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NbTabsetModule,
    NbListModule,
    NbTagModule,
    NbRadioModule,
    NbCheckboxModule,
    NbToggleModule,
  ],
  declarations: [...pipes, ...controlTowerComponents],
  exports: [...pipes, ...controlTowerComponents],
  entryComponents: [],
})
export class ControlTowerModule {}
