import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserComponent } from './user/user.component';
import { CreateUserComponent } from './user/create/create-user.component';
import { ListUserComponent } from './user/list/list-user.component';
import { UpdateUserComponent } from './user/update/update-user.component';

export const authComponents = [
  ChangePasswordComponent,
  UserComponent,
  CreateUserComponent,
  ListUserComponent,
  UpdateUserComponent,
];
