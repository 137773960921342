import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { IUser } from '../user.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ISettingAlert } from '../../../utils/alert/alert.interface';
import { CreateUserValidator } from './create-user.validator';
import {
  TModulesApp,
  IPermission,
  TITLE_MODULE,
  TActionDataEntry,
} from '../../../../middleware/token/token.interface';
import { ViewService } from '../../../mangement/data-entry/view/view.service';
import { IView } from '../../../mangement/data-entry/view/view.interface';
@Component({
  selector: 'ngx-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  @Output() userCreated = new EventEmitter<boolean>();
  fg = this.fb.group({
    email: [],
  });
  roleCode: number = 0;
  loading = false;
  users: Array<IUser> = [];
  settingAlert: ISettingAlert = {};
  titleModules = TITLE_MODULE;
  permissions: IPermission[] = [];
  beAbleViewsBoarsBI = false;
  beAbleAction = false;
  views: Array<IView> = [];

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private viewService: ViewService
  ) {}

  async ngOnInit() {
    await this.getUsers();
    await this.getViews();
    this.fg = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(5)]],
    });
    this.fg.setValidators(CreateUserValidator.validateEmail(this.users));
    this.setInitPermissions();
  }

  async onSubmitFg() {
    const user = this.fg.value as IUser;
    try {
      this.loading = true;
      const response = await this.userService.createUserAsync({
        email: user.email + '@alicorp.com.bo',
        permissions: this.permissions,
      });
      this.loading = false;
      this.settingAlert = response;
      this.fg.reset();
      this.userCreated.emit(true);
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  getStatusFg(itemFg: string): string {
    if (!(this.fg.get(itemFg).dirty || this.fg.get(itemFg).touched)) {
      return 'info';
    }
    if (this.fg.get(itemFg).valid) {
      return 'success';
    }
    return 'danger';
  }

  async getUsers() {
    try {
      this.loading = true;
      this.users = await this.userService.listUsersAsync();
      this.loading = false;
      this.fg.reset();
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }

  toggle(event: boolean, module: TModulesApp) {
    this.permissions.find((item) => item.module === module).permitted = event;
    this.beAbleViewsBoarsBI =
      module === 'boardsBI' ? event : this.beAbleViewsBoarsBI;
    this.beAbleAction = module === 'adminDataEntry' ? event : this.beAbleAction;
  }

  toggleBoardsBI(event: boolean, viewId: number) {
    let viewsIds = this.permissions.find(
      (item) => item.module === 'boardsBI'
    ).viewsIds;
    if (event) {
      viewsIds.push(viewId);
    } else {
      viewsIds = viewsIds.filter((item) => item !== viewId);
    }
    this.permissions.find((item) => item.module === 'boardsBI').viewsIds =
      viewsIds;
  }

  toggleActionDataEntry(event: boolean, typeAction: TActionDataEntry) {
    let actionDataEntry = this.permissions.find(
      (item) => item.module === 'adminDataEntry'
    ).actionDataEntry;
    if (event) {
      actionDataEntry.push(typeAction);
    } else {
      actionDataEntry = actionDataEntry.filter((item) => item !== typeAction);
    }
    this.permissions.find(
      (item) => item.module === 'adminDataEntry'
    ).actionDataEntry = actionDataEntry;
  }

  setInitPermissions() {
    const permission: IPermission[] = [];
    for (const titleModule of this.titleModules) {
      if (titleModule.module === 'boardsBI') {
        permission.push({
          module: titleModule.module,
          permitted: false,
          viewsIds: [],
        });
      } else if (titleModule.module === 'adminDataEntry') {
        permission.push({
          module: titleModule.module,
          permitted: false,
          actionDataEntry: [],
        });
      } else {
        permission.push({
          module: titleModule.module,
          permitted: false,
        });
      }
    }
    this.permissions = permission;
  }

  async getViews() {
    try {
      this.loading = true;
      this.views = await this.viewService.listViewsAsync();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.settingAlert = err as HttpErrorResponse;
    }
  }
}
