<nb-card [nbSpinner]="loading" nbSpinnerStatus="info">
  <nb-card-header class="text-primary">
    <nb-icon icon="file-remove-outline"></nb-icon>
    <strong> ELIMINAR DATA ENTRY </strong>
  </nb-card-header>
  <nb-card-body>
    <ngx-alert [setting]="settingAlert"></ngx-alert>
    <form [formGroup]="fg" (ngSubmit)="onSubmitFg()">
      <div class="form-group row">
        <label for="inputView" class="label col-sm-3 col-form-label"
          >Vista</label
        >
        <div class="col-sm-9">
          <nb-select
            fullWidth
            filled
            placeholder="Seleccionar Vista"
            formControlName="viewId"
          >
            <nb-option *ngFor="let view of views" [value]="view.id">{{
              view.nameView
            }}</nb-option>
          </nb-select>
        </div>
      </div>

      <div class="form-group row">
        <label for="inputTemplate" class="label col-sm-3 col-form-label"
          >Plantilla</label
        >
        <div class="col-sm-9">
          <nb-select
            fullWidth
            filled
            placeholder="Seleccionar Plantilla"
            formControlName="nameTemplate"
          >
            <nb-option
              *ngFor="let template of templateView"
              [value]="template.nameTemplate"
              >{{ template.nameTemplate }}</nb-option
            >
          </nb-select>
        </div>
      </div>

      <div class="form-group row">
        <label for="inputCode" class="label col-sm-3 col-form-label"
          >Codigo</label
        >
        <div class="col-sm-9">
          <input
            nbInput
            type="number"
            fullWidth
            filled
            [status]="getStatusFg('code')"
            placeholder="Codigo"
            formControlName="code"
          />
          <div
            *ngIf="
              fg.get('code').invalid &&
              (fg.get('code').dirty || fg.get('code').touched)
            "
            class="text-danger"
          >
            <div *ngIf="fg.get('code').errors?.notNumber">
              Debe contener solo numeros.
            </div>
            <div *ngIf="fg.get('code').errors?.rangeNumber">
              Debe tener de 12 a 15 numeros.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="offset-sm-3 col-sm-9">
          <button
            type="submit"
            nbButton
            outline
            status="danger"
            [disabled]="fg.invalid"
          >
            <nb-icon icon="close-outline"></nb-icon>
            ELIMINAR
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
